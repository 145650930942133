w,x,y,z
b,203.3,644.07,5
p,309.98,399.98,5
m,-159.4,285.25,3
f,-74.44,530.28,1
d,57.35,753.76,5
t,269.96,549.36,4
n,-195.7,822.43,5
l,-42.24,313.96,0
g,57.2,314.14,0
k,-86.52,282.2,2
ng,225,217.5,3
h,127.6,22.24,2
gw,-43.58,47.42,0
kw,180.13,371.56,5
w,-67.6,807.8,5
z,317.61,956.83,5
c,1.88,1011.3,4
s,181.94,84.51,1
j,-206.92,50.34,4
aa,283.28,1136.64,4
aai,259.51,470.89,5
aau,-40.44,412.92,2
aam,-142.3,1188.4,3
aan,9.4,842.52,2
aang,61.34,849.73,5
aap,-176.1,527.1,3
aat,-167.28,1049.37,5
aak,-105.6,839.4,2
ai,165.25,806.85,3
au,-47.36,893,2
am,125.3,166.6,3
an,-65.48,670.44,2
ang,109.56,158.8,0
ap,-87.7,22,3
at,49.22,827.82,4
ak,-17.35,1213.65,3
e,-32.38,272.62,0
ei,92,61.74,1
eu,19.13,106.5,1
em,-61.86,1380.12,5
eng,-237.56,619.01,5
ep,147.16,1381.1,5
ek,149.39,726.27,1
i,-174.6,373.45,3
iu,268.88,128.22,4
im,45.84,750.4,2
in,19.76,1357.56,4
ing,186.08,1376.46,4
ip,274.04,707.4,4
it,-217.61,1352.26,5
ik,317.96,1544.62,5
o,171.2,472.68,1
oi,155.05,639.55,3
ou,-11.98,387.39,1
on,106.52,413.42,0
ong,-268.5,1570.31,5
ot,242.01,1301.09,5
ok,109.85,681.1,5
oe,52.06,96.86,0
oeng,-268.43,302.82,5
oek,79.56,696.84,2
eoi,311.03,727.09,5
eon,8.5,30,3
eot,232.1,37.9,3
u,-64.72,849.84,4
ui,77.93,723.63,1
un,-4.98,369.3,0
ung,-48.42,1073.38,5
ut,119.04,574.24,2
uk,148.85,547.45,3
yu,-59.28,206.32,2
yun,119.06,1306.74,4
yut,221,863.55,3
